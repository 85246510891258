<template>
  <div class="404">
		<page-header
			title="Nothing found"
		/>
		<page-container>
			<div class="row">
				<div class="col-md-6">
					<p>Sorry, we couldn't find that page, please return to the homepage.</p>
					<router-link
						:to="$GLOBALS.PATH_WELCOME"
						class="btn btn-secondary"
						role="button"
					>Return Home</router-link>
				</div>
			</div>
		</page-container>
  </div>
</template>

<script>
// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'

export default {
	components: {
		pageHeader,
		pageContainer,
	}
}
</script>
